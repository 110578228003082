<template>
<div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">

    <main>
        <div class="main_content align-items-center ">
            <div class="top_heading mb_64">
                <div class="row justify-content-xl-between">
                    <div class="col-12 col-xl-3 col-xxl-5">
                        <div class="heading">
                            <h2>Self-Assessment</h2>
                            <h3>Employee Dashboard</h3>
                        </div>
                    </div>
                    <div class="col-12 col-xxl-7 col-xl-9">
                        <div class="date_toolbar_main">
                            <div class="bg-white date-toolbar">
                                <div class="row">
                                    <div class="col-9 align-self-center">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="date">
                                                    <div class="heading_5">From</div>
                                                    <div class="text_black font_size_14"><span><svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17.5" height="17.5"
                                                                viewBox="0 0 17.5 17.5">
                                                                <path data-name="Path 131"
                                                                    d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                                                    transform="translate(-2.25 -2.25)"
                                                                    style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px" />
                                                            </svg></span>01-10-2022</div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="date pl_6">
                                                    <div class="heading_5">To</div>
                                                    <div class="text_black font_size_14">
                                                        <span><svg xmlns="http://www.w3.org/2000/svg"
                                                                width="17.5" height="17.5"
                                                                viewBox="0 0 17.5 17.5">
                                                                <path data-name="Path 131"
                                                                    d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                                                    transform="translate(-2.25 -2.25)"
                                                                    style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px" />
                                                            </svg></span>01-10-2022
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 align-self-center">
                                        <div class="form-group region_select mb-0">
                                            <v-select v-model="selected"
                                                :options="['By Region','Client User']"
                                                placeholder="By Region" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="most_visited">
                <div class="row">
                    <div class="col-xl-6">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="d-flex">
                                    <div class="card_box">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-xl-6">
                        <div class="activity_region ">
                            <div class="row">
                                <div class="col-sm-6">
                                    <h2 class="mb_20">Total Activity by Region</h2>
                                </div>
                                <div class="col-sm-3">
                                    <div class="visited_pages">
                                        <div class="heading">
                                            <div class="form-group">
                                                <v-select v-model="selected"
                                                    :options="['By Region','Client User']"
                                                    placeholder="By Region" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-3">
                                    <div class="d-flex justify-content-end ">
                                                <div>
                                                    <a href="http://" data-bs-toggle="modal"
                                                        data-bs-target="#download"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16" viewBox="0 0 16 16">
                                                            <g data-name="Group 12689">
                                                                <path data-name="Path 114"
                                                                    d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                                                    transform="translate(-3 -3)"
                                                                    style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                            </g>
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div  class="dropdown calender_dropdown">
                                                    <a href="http://" class="ml_20 dropdown-toggle"
                                                        role="button" id="dropdownMenuLink"
                                                        data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16" viewBox="0 0 16 16">
                                                            <path data-name="Path 130"
                                                                d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                transform="translate(-2 -2)"
                                                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                        </svg>
                                                    </a>
                                                    <div class="dropdown-menu px_20 py_20 calender_date"
                                                        aria-labelledby="dropdownMenuLink">

                                                        <div class="date pl_6">
                                                            <div class="heading_5 font_bold mb_10">From
                                                            </div>
                                                            <div
                                                                class="text_black font_size_14 border px_12 py_10">
                                                                <span class="mr_10"><svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16" height="16"
                                                                        viewBox="0 0 16 16">
                                                                        <path data-name="Path 130"
                                                                            d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                            transform="translate(-2 -2)"
                                                                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                    </svg></span>01-10-2022
                                                            </div>
                                                        </div>
                                                        <div class="date pl_6 mt_20">
                                                            <div class="heading_5 font_bold mb_10">To</div>
                                                            <div
                                                                class="text_black font_size_14 border px_12 py_10">
                                                                <span class="mr_10"><svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16" height="16"
                                                                        viewBox="0 0 16 16">
                                                                        <path data-name="Path 130"
                                                                            d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                            transform="translate(-2 -2)"
                                                                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                    </svg></span>01-10-2022
                                                            </div>
                                                        </div>
                                                        <div class="button mt_30">
                                                            <a href="#"
                                                                class="btn btn_primary px_14">Update</a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                </div>
                            </div>
                        </div>
                        <div class="mapping">
                            <div class="row">
                                <div class="col-md-7 text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="265.36" height="277.376"
                                        viewBox="0 0 265.36 277.376">
                                        <g data-name="Group 12332">
                                            <path data-name="Union 1"
                                                d="M0 79.561a134.981 134.981 0 0 1 .852-15.079l.078.01H.906C3.911 42.135 12.927 18.1 26.506 0l18.507 14.174L130.3 80.663l.094.012-.025.042.015.012h-.021L63.312 192.677A131.705 131.705 0 0 1 0 79.561z"
                                                transform="translate(0 65.134)" style="fill:#ff6a81" />
                                            <path
                                                d="M2187.2-3035.219v132.672l-.006.028v.043c0 .018-.008.038-.012.056a1.111 1.111 0 0 1-.025.145.49.49 0 0 1-.015.105l-.005.013a.764.764 0 0 1-.031.081c-.008.019-.008.039-.014.055s-.031.046-.042.072-.028.077-.048.113l-.014.02L2118.824-2788a132.475 132.475 0 0 0 66.989 18.138c73.159 0 132.68-59.521 132.68-132.681 0-72.7-58.77-131.922-131.292-132.672"
                                                transform="translate(-2053.133 3047.242)"
                                                style="fill:#6665dd" />
                                            <text data-name="60%" transform="translate(168.25 159.965)"
                                                style="fill:#fff;font-size:21px;font-family:Montserrat-Bold,Montserrat;font-weight:700">
                                                <tspan x="0" y="0">60%</tspan>
                                            </text>
                                            <text data-name="25%" transform="translate(30.217 159.965)"
                                                style="fill:#fff;font-size:21px;font-family:Montserrat-Bold,Montserrat;font-weight:700">
                                                <tspan x="0" y="0">25%</tspan>
                                            </text>
                                            <g>
                                                <path
                                                    d="M1927.328-2894.478v-140.749a142.8 142.8 0 0 0-111.741 55.176l38.353 29.372 73.388 56.2"
                                                    transform="translate(-1796.929 3035.227)"
                                                    style="fill:#f2a149" />
                                                <text data-name="15%" transform="translate(69.182 58.548)"
                                                    style="fill:#fff;font-size:21px;font-family:Montserrat-Bold,Montserrat;font-weight:700">
                                                    <tspan x="0" y="0">15%</tspan>
                                                </text>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="col-md-5 align-self-end">
                                    <div class="row mb_32 justify-content-end">
                                        <div class="col-9 text-end">
                                            <div class="tab_report toggle_switch">
                                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link active" id="percent-tab"
                                                            data-bs-toggle="tab" data-bs-target="#percent"
                                                            type="button" role="tab" aria-controls="percent"
                                                            aria-selected="true">%</button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link" id="hash-tab"
                                                            data-bs-toggle="tab" data-bs-target="#hash"
                                                            type="button" role="tab" aria-controls="hash"
                                                            aria-selected="false">#</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>                                       
                                    </div>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="percent" role="tabpanel"
                                            aria-labelledby="percent-tab">
                                            <div class="registrant">
                                                <div
                                                    class="d-flex justify-content-between registrant_section">
                                                    <div class="name">
                                                        <h3><span class="circle blue"></span> Northeast
                                                        </h3>
                                                    </div>
                                                    <div class="percent">
                                                        <h3>60</h3>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex justify-content-between registrant_section">
                                                    <div class="name">
                                                        <h3><span class="circle pink"></span> Southwest</h3>
                                                    </div>
                                                    <div class="percent">
                                                        <h3>25</h3>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex justify-content-between registrant_section mb-0">
                                                    <div class="name">
                                                        <h3><span class="circle orange"></span> Midwest</h3>
                                                    </div>
                                                    <div class="percent">
                                                        <h3>15</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="hash" role="tabpanel"
                                            aria-labelledby="hash-tab">
                                            <div class="registrant">
                                                <div
                                                    class="d-flex justify-content-between registrant_section">
                                                    <div class="name">
                                                        <h3><span class="circle blue"></span> Registrants
                                                        </h3>
                                                    </div>
                                                    <div class="percent">
                                                        <h3>60px</h3>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex justify-content-between registrant_section">
                                                    <div class="name">
                                                        <h3><span class="circle pink"></span> Self
                                                            Assessment</h3>
                                                    </div>
                                                    <div class="percent">
                                                        <h3>25%</h3>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex justify-content-between registrant_section mb-0">
                                                    <div class="name">
                                                        <h3><span class="circle orange"></span> Ergo Eval
                                                        </h3>
                                                    </div>
                                                    <div class="percent">
                                                        <h3>15%</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="self_report">
                <div class="row">
                    <div class="col-xl-6">
                        <div class="card px-0 mb_30">
                            <div class="activity_region border-bottom px_20 pb_14">
                                <div class="row">
                                    <div class="col-sm-7 align-self-center">
                                        <h2 class="mb_20">Self-Reported Discomfort</h2>
                                    </div>
                                    <div class="col-sm-3 align-self-center">
                                        <div class="visited_pages">
                                            <div class="heading">
                                                <div class="form-group mb-0">
                                                    <v-select v-model="selected"
                                                        :options="['By Region','Client User']"
                                                        placeholder="By Region" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-sm-2 text-end align-self-center">
                                        <div class="d-flex justify-content-end ">
                                                <div>
                                                    <a href="http://" data-bs-toggle="modal"
                                                        data-bs-target="#download"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16" viewBox="0 0 16 16">
                                                            <g data-name="Group 12689">
                                                                <path data-name="Path 114"
                                                                    d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                                                    transform="translate(-3 -3)"
                                                                    style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                            </g>
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div  class="dropdown calender_dropdown">
                                                    <a href="http://" class="ml_20 dropdown-toggle"
                                                        role="button" id="dropdownMenuLink"
                                                        data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16" viewBox="0 0 16 16">
                                                            <path data-name="Path 130"
                                                                d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                transform="translate(-2 -2)"
                                                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                        </svg>
                                                    </a>
                                                    <div class="dropdown-menu px_20 py_20 calender_date"
                                                        aria-labelledby="dropdownMenuLink">

                                                        <div class="date pl_6">
                                                            <div class="heading_5 font_bold mb_10">From
                                                            </div>
                                                            <div
                                                                class="text_black font_size_14 border px_12 py_10">
                                                                <span class="mr_10"><svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16" height="16"
                                                                        viewBox="0 0 16 16">
                                                                        <path data-name="Path 130"
                                                                            d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                            transform="translate(-2 -2)"
                                                                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                    </svg></span>01-10-2022
                                                            </div>
                                                        </div>
                                                        <div class="date pl_6 mt_20">
                                                            <div class="heading_5 font_bold mb_10">To</div>
                                                            <div
                                                                class="text_black font_size_14 border px_12 py_10">
                                                                <span class="mr_10"><svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16" height="16"
                                                                        viewBox="0 0 16 16">
                                                                        <path data-name="Path 130"
                                                                            d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                            transform="translate(-2 -2)"
                                                                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                    </svg></span>01-10-2022
                                                            </div>
                                                        </div>
                                                        <div class="button mt_30">
                                                            <a href="#"
                                                                class="btn btn_primary px_14">Update</a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_report px_20 py_20">
                                <ul class="nav nav-tabs mb_16" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                            data-bs-target="#home" type="button" role="tab"
                                            aria-controls="home" aria-selected="true">Registrants</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                            data-bs-target="#profile" type="button" role="tab"
                                            aria-controls="profile" aria-selected="false">SA
                                            activity</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab"
                                            data-bs-target="#contact" type="button" role="tab"
                                            aria-controls="contact" aria-selected="false">Eval
                                            Reports</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="Eval-tab" data-bs-toggle="tab"
                                            data-bs-target="#Eval" type="button" role="tab"
                                            aria-controls="Eval" aria-selected="false">Ergo Eval</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div class="graph">
                                            <img src="../../assets/admin/images/stand_graph.png" alt="graph">
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="profile" role="tabpanel"
                                        aria-labelledby="profile-tab">
                                        <div class="graph">
                                            <img src="../../assets/admin/images/stand_graph.png" alt="graph">
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="contact" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <div class="graph">
                                            <img src="../../assets/admin/images/stand_graph.png" alt="graph">
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="Eval" role="tabpanel"
                                        aria-labelledby="Eval-tab">
                                        <div class="graph">
                                            <img src="../../assets/admin/images/stand_graph.png" alt="graph">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="card px_20">
                            <div class="activity_region px-0 border-bottom tab_report mb_18 pb_14">
                                <div class="row">
                                    <div class="col-sm-7 align-self-center">
                                        <h2 class="mb_20">Site Registrants</h2>
                                    </div>
                                    <div class="col-sm-5 align-self-center text-end">
                                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link active" id="Unique-tab"
                                                    data-bs-toggle="tab" data-bs-target="#Unique"
                                                    type="button" role="tab" aria-controls="Unique"
                                                    aria-selected="true">Unique</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="Revisits-tab"
                                                    data-bs-toggle="tab" data-bs-target="#Revisits"
                                                    type="button" role="tab" aria-controls="Revisits"
                                                    aria-selected="false">Revisits</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="Unique" role="tabpanel"
                                    aria-labelledby="Unique-tab">
                                    <div class="activity_region mb_10">
                                        <div class="row">
                                            <div class="col-sm-7 align-self-center">
                                                <div class="d-flex align-items-center">
                                                    <div class="new_user">
                                                        <a href="#" class="">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                width="25" height="25" viewBox="0 0 25 25"
                                                                class="mr_10">
                                                                <g data-name="Group 12685"
                                                                    transform="translate(-1031 -251)">
                                                                    <rect data-name="Rectangle 2213"
                                                                        width="25" height="25" rx="12.5"
                                                                        transform="translate(1031 251)"
                                                                        style="fill:#29cb97" />
                                                                    <g data-name="Group 12684">
                                                                        <path data-name="Path 125"
                                                                            d="M9.714 8.573v1.194A3.429 3.429 0 0 0 5.143 13H4a4.571 4.571 0 0 1 5.714-4.428zm-1.143-.716A3.429 3.429 0 1 1 12 4.429a3.428 3.428 0 0 1-3.429 3.428zm0-1.143a2.286 2.286 0 1 0-2.285-2.285 2.285 2.285 0 0 0 2.285 2.285zM12 10.143V8.429h1.143v1.714h1.714v1.143h-1.714V13H12v-1.714h-1.714v-1.143z"
                                                                            transform="translate(1034 256.999)"
                                                                            style="fill:#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>New Users
                                                        </a>
                                                    </div>
                                                    <div class="value ml_20 font_size_20 font_bold">
                                                        <a href="#" class="">
                                                            50 <svg xmlns="http://www.w3.org/2000/svg"
                                                                width="12.828" height="15.271"
                                                                viewBox="0 0 12.828 15.271">
                                                                <path data-name="Path 79"
                                                                    d="m5 8 5-5m0 0 5 5m-5-5v12.857"
                                                                    transform="translate(-3.586 -1.586)"
                                                                    style="fill:none;stroke:#29cb97;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-3 align-self-center text-lg-end">
                                                <div class="visited_pages">
                                                    <div class="heading">
                                                        <div class="form-group mb-0">
                                                            <v-select v-model="selected"
                                                                :options="['By Region','Client User']"
                                                                placeholder="By Region" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-sm-2 text-end align-self-center">
                                                <div class="d-flex justify-content-end ">
                                                <div>
                                                    <a href="http://" data-bs-toggle="modal"
                                                        data-bs-target="#download"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16" viewBox="0 0 16 16">
                                                            <g data-name="Group 12689">
                                                                <path data-name="Path 114"
                                                                    d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                                                    transform="translate(-3 -3)"
                                                                    style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                            </g>
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div  class="dropdown calender_dropdown">
                                                    <a href="http://" class="ml_20 dropdown-toggle"
                                                        role="button" id="dropdownMenuLink"
                                                        data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16" viewBox="0 0 16 16">
                                                            <path data-name="Path 130"
                                                                d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                transform="translate(-2 -2)"
                                                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                        </svg>
                                                    </a>
                                                    <div class="dropdown-menu px_20 py_20 calender_date"
                                                        aria-labelledby="dropdownMenuLink">

                                                        <div class="date pl_6">
                                                            <div class="heading_5 font_bold mb_10">From
                                                            </div>
                                                            <div
                                                                class="text_black font_size_14 border px_12 py_10">
                                                                <span class="mr_10"><svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16" height="16"
                                                                        viewBox="0 0 16 16">
                                                                        <path data-name="Path 130"
                                                                            d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                            transform="translate(-2 -2)"
                                                                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                    </svg></span>01-10-2022
                                                            </div>
                                                        </div>
                                                        <div class="date pl_6 mt_20">
                                                            <div class="heading_5 font_bold mb_10">To</div>
                                                            <div
                                                                class="text_black font_size_14 border px_12 py_10">
                                                                <span class="mr_10"><svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16" height="16"
                                                                        viewBox="0 0 16 16">
                                                                        <path data-name="Path 130"
                                                                            d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                            transform="translate(-2 -2)"
                                                                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                    </svg></span>01-10-2022
                                                            </div>
                                                        </div>
                                                        <div class="button mt_30">
                                                            <a href="#"
                                                                class="btn btn_primary px_14">Update</a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="graph mb_34">
                                        <img src="../../assets/admin/images/line_graph.png" alt="graph">
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Revisits" role="tabpanel"
                                    aria-labelledby="Revisits-tab">
                                    <div class="activity_region mb_10">
                                        <div class="row">
                                            <div class="col-md-7 align-self-center">
                                                <div class="d-flex align-items-center">
                                                    <div class="new_user">
                                                        <a href="#" class="">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                width="25" height="25" viewBox="0 0 25 25"
                                                                class="mr_10">
                                                                <g data-name="Group 12685"
                                                                    transform="translate(-1031 -251)">
                                                                    <rect data-name="Rectangle 2213"
                                                                        width="25" height="25" rx="12.5"
                                                                        transform="translate(1031 251)"
                                                                        style="fill:#29cb97" />
                                                                    <g data-name="Group 12684">
                                                                        <path data-name="Path 125"
                                                                            d="M9.714 8.573v1.194A3.429 3.429 0 0 0 5.143 13H4a4.571 4.571 0 0 1 5.714-4.428zm-1.143-.716A3.429 3.429 0 1 1 12 4.429a3.428 3.428 0 0 1-3.429 3.428zm0-1.143a2.286 2.286 0 1 0-2.285-2.285 2.285 2.285 0 0 0 2.285 2.285zM12 10.143V8.429h1.143v1.714h1.714v1.143h-1.714V13H12v-1.714h-1.714v-1.143z"
                                                                            transform="translate(1034 256.999)"
                                                                            style="fill:#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>New Users
                                                        </a>
                                                    </div>
                                                    <div class="value ml_20 font_size_20 font_bold">
                                                        <a href="#" class="">
                                                            50 <svg xmlns="http://www.w3.org/2000/svg"
                                                                width="12.828" height="15.271"
                                                                viewBox="0 0 12.828 15.271">
                                                                <path data-name="Path 79"
                                                                    d="m5 8 5-5m0 0 5 5m-5-5v12.857"
                                                                    transform="translate(-3.586 -1.586)"
                                                                    style="fill:none;stroke:#29cb97;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 align-self-center text-lg-end">
                                                <div class="visited_pages">
                                                    <div class="heading">
                                                        <div class="form-group mb-0">
                                                            <v-select v-model="selected"
                                                                :options="['By Region','Client User']"
                                                                placeholder="By Region" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-2 text-end align-self-center">
                                                <div class="d-flex justify-content-center">
                                                    <a href="http://"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16" viewBox="0 0 16 16">
                                                            <g data-name="Group 12689">
                                                                <path data-name="Path 114"
                                                                    d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                                                    transform="translate(-3 -3)"
                                                                    style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                            </g>
                                                        </svg>
                                                    </a>
                                                    <a href="http://" class="ml_20"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16" viewBox="0 0 16 16">
                                                            <path data-name="Path 130"
                                                                d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                transform="translate(-2 -2)"
                                                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="graph mb_34">
                                        <img src="../../assets/admin/images/line_graph.png" alt="graph">
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

         
        <div class="modal fade download_modal" id="download" tabindex="-1" aria-labelledby="downloadLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="downloadLabel">
                            <h3 class="heading_2">Download</h3>
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row justify-content-around">
                            <div class="col-md-3 ">
                                <div class="image_upload text-center disabled">
                                    <a href="#" class=" text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="38.891" height="38.063"
                                            viewBox="0 0 38.891 38.063" class="d-block">
                                            <path data-name="Path 143"
                                                d="m4 31.047 10.336-10.336a4.508 4.508 0 0 1 6.374 0l10.337 10.336m-4.508-4.508 3.575-3.575a4.508 4.508 0 0 1 6.374 0l3.575 3.575M26.539 13.016h.023M8.508 40.063h27.047a4.508 4.508 0 0 0 4.508-4.508V8.508A4.508 4.508 0 0 0 35.555 4H8.508A4.508 4.508 0 0 0 4 8.508v27.047a4.508 4.508 0 0 0 4.508 4.508z"
                                                transform="translate(-2.586 -3)"
                                                style="fill:none;stroke:#6d6d6d;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                        </svg> Download as a document
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-3 ">
                                <div class="image_upload text-center">
                                    <a href="#" class=" text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="38.891" height="38.063"
                                            viewBox="0 0 38.891 38.063" class="d-block">
                                            <path data-name="Path 143"
                                                d="m4 31.047 10.336-10.336a4.508 4.508 0 0 1 6.374 0l10.337 10.336m-4.508-4.508 3.575-3.575a4.508 4.508 0 0 1 6.374 0l3.575 3.575M26.539 13.016h.023M8.508 40.063h27.047a4.508 4.508 0 0 0 4.508-4.508V8.508A4.508 4.508 0 0 0 35.555 4H8.508A4.508 4.508 0 0 0 4 8.508v27.047a4.508 4.508 0 0 0 4.508 4.508z"
                                                transform="translate(-2.586 -3)"
                                                style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                        </svg> Download as an image
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-top-0 mx-auto mb_50">
                        <button type="button" class="btn btn_primary_light mr_30" data-bs-dismiss="modal">Clear</button>
                        <button type="button" class="btn btn_primary">Download</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import "vue-select/dist/vue-select.css";
    export default {
        name: "client_activity",
    }
</script>